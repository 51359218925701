<template>
  <div class="Client font">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div
        class="content-wrapper container-xxl p-0 font"
        v-if="permissionCheck.viewAny == false"
      >
        <div class="p-2">
          <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
        </div>
      </div>


      <div class="content-wrapper container-xxl p-0 font" v-else>

        <banner title="VeryFication" :breadcrumb="[
           {
              label: 'Dashboard',
            },
            {
              label: 'VeryFication',
            }
          ]">
        </banner>
      
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card" >
              <div class="card-body">

                <div class="row">
                  <div class="col text font">

                    <h4><b style="color: #00364f; font-weight: bolder">Project Verification</b></h4>
                  </div>
                  <div class="col text-end ">
                  <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>


                <!-- start list area  -->
                <div class="row" style="margin-top:5px">
                
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                   
                  >
                    <div class="container-fluid table-scroll" style="margin-left: -5px">
                      <table class="table table-hover table-sm text font">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Client Name</th>
                          <th class="text-truncate">Professional</th>
                          <th class="text-truncate">Email</th>
                          <th class="text-truncate">Service</th>
                          <th class="text-truncate">Project Status</th>
                          <th class="text-truncate">Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>
                        <tbody class="text-truncate">
                          <tr v-for="(project, index) in clientServices" :key="index">
                            <td>
                              <span v-if="project.client_service">
                                {{ project.client_service.clientServiceNo }}</span
                              >
                            </td>
                            <td>
                              <span v-if="project">
                                {{ dateTime(project.date) }}
                              </span>
                            </td>
                            <td>
                              <span v-if="project.client_service">
                                <span v-if="project.client_service.client">
                                  {{ project.client_service.client.fname }}
                                  {{ project.client_service.client.lname }}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span v-if="project.professional">

<div>
  <strong>
    {{ project.professional.name }}
  </strong>

</div>
<div>
  {{ project.professional.code }}
</div>
                                
                              </span>
                            </td>

                            <td class="text-truncate">
                              <span v-if="project.client_service">
                                <span v-if="project.client_service.client">
                                  {{ project.client_service.client.email }}
                                </span>
                              </span>
                            </td>
                            <td class="text-truncate">{{ project.service.name }}</td>
                            <!-- <td class="text-truncate">
                              <div class="progress" style="height: 7px">
                                <div
                                  style="background-color: #f21300; width: 50%"
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="40"
                                  aria-valuemin="40"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td> -->
                            <td
                                v-if="project.client_service"
                                class="text-truncate"
                                style="text-transform: capitalize"
                              >
                                <p
                                  v-if="project.client_service.status == 'assigned'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.client_service.status }}</b>
                                </p>
                                <p
                                  v-if="project.client_service.status == 'completed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 90px;
                                    background-color: #008000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.client_service.status }}</b>
                                </p>

                                <p
                                  v-if="project.client_service.status == 'pending'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #cdcdcd;
                                    color: white;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>New</b>
                                </p>
                                <p
                                  v-if="project.client_service.status == 'processed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #ffaa00;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.client_service.status }}</b>
                                </p>
                                <p
                                  v-if="project.client_service.status == 'hold'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #8f8d8e;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.client_service.status }}</b>
                                </p>
                                <p
                                  v-if="project.client_service.status == 'rejected'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.client_service.status }}</b>
                                </p>
                                <p
                                  v-if="project.client_service.status == 'cancelled'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #f21000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.client_service.status }}</b>
                                </p>
                                <p
                                  v-if="project.client_service.status == 'upgrade'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #ee0def;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ project.client_service.status }}</b>
                                </p>
                              </td>

                            <td class="text-truncate">
                              <p
                                v-if="project.status == 'pending'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: gray;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>New</b>
                              </p>
                              <p
                                v-if="project.status == 'assigned'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #349c9e;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'completed'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #3ab04b;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'finalize'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #430194;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>

                              <p
                                v-if="project.status == 'hold'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #8f8d8e;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>

                              <p
                                v-if="project.status == 'rejected'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>

                              <p
                                v-if="project.status == 'cancelled'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #f50303;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'upgrade'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #ee0def;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                            </td>
                            <td class="text-truncate">
                              <!-- <button type="button" data-bs-toggle="modal" data-bs-target="#addon"
                                  class="btn btns btn-sm btn-success" @click="editModule(mod)"
                                  style="padding:5px 4.5px 5px 4.5px;">
                                  <font-awesome-icon icon="edit" />
                                </button> -->
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  class="btn btn-sm text-white"
                                  @click="valueAssign(project)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#projectStatusChange"
                                  style="
                                    background-color: green;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                <i
                                      class="fa fa-check-square-o"
                                      aria-hidden="true"
                                    ></i>
                                </button>
                                <router-link
                                  v-if="permissionCheck.view != false"
                                  :to="`/staff/project-detailes/${project.client_service.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <!-- <router-link
                                  to="#"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: green;
                                  "
                                >
                                  <font-awesome-icon icon="edit" />
                                </router-link> -->
                                <!-- <router-link
                                  to="#"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: red;
                                  "
                                >
                                  <font-awesome-icon icon="remove" />
                                </router-link> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadClients(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadClients(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadClients(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadClients(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadClients(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClients(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClients(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClients(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClients(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClients(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClients(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClients(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="projectStatusChange"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #00364f">
          <h4 class="modal-title text-white" id="exampleModalLabel">
            <strong>Project</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col" v-if="this.form.errors">
              <div
                class="alert alert-danger p-1"
                role="alert"
                v-if="this.form.errors.error"
              >
                {{ this.form.errors.error }}
              </div>
            </div>
          </div>
          <form @submit.prevent="statusChangeSave">
            <div class="container">
              <!-- <div v-if="stsCheck == 'assigned'">
                <div class="form-check p-1">
                  <input
                    class="form-check-input"
                    @click="statusChange('accept')"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label> Accept </label>
                </div>

                <div class="form-check p-1">
                  <input
                    class="form-check-input"
                    @click="statusChange('decline')"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label> Decline </label>
                </div>
              </div> -->

              <!-- <div v-if="stsCheck == 'processed'"> -->
      
              <div class="form-check p-1">
                <input
                value="reject"
                  v-model="catchStatus"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="rejectStatus"
                  checked 
                />
                <label  for="rejectStatus"> Reject </label>
              </div>

              <div class="form-check p-1">
                <input
                value="complete"
                  v-model="catchStatus"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="completeStatus"
                />
                <label for="completeStatus"> Complete </label>
              </div>
              <!-- </div> -->

              <textarea
                style="margin-left: -10px; width: 100%"
                class="form-control"
                v-model="form.remark"
                rows="3"
                placeholder="reason"
              ></textarea>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  

  
      <!-- Right Sidebar starts -->
      <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
  <!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="challanNo" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Challan NO.
  </label>
</div>
</li> -->


<li class="list-group-item"><div class="form-check">
  <input v-model="filterType" value="date" class="form-check-input"  type="radio" name="flexRadioDefault" id="date">
  <label class="form-check-label" for="date">
    Date
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input v-model="filterType" value="client_service_id" class="form-check-input"  type="radio" name="flexRadioDefault" id="client_service_id">
  <label class="form-check-label" for="client_service_id">
    Project ID
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <input type="text" v-if="filterType == 'challanNo'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <input type="text" v-if="filterType == 'client_service_id'" @change="findRecordeByProjectId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 







</div>

<button v-if="filterType != 'client_service_id'"  @click="loadClients()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>


<script>
import Permissions from "../../../mixins/permission";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
import Banner from "../../../components/staff/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
// var _ = require("lodash");
export default {
  name: "Projects",
  mixins: [Permissions],
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue:'',
      filterType:'',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      catchStatus: "reject",
      pre: true,
      proServices: [],
      proSteps: [],
      form: {
        client_service_id: null,
        remark: "",
        errors: {},
      },
      selectedpro: [],
      loading: false,
      Externalprofessionals: [],
      Internalprofessionals: [],
      clientServices: [],
      statusQueryArray: [],
      tasks: [],
      steps: [],
      assign_form: {
        client_service_id: null,
        multipleAsignIdProfessional: [],
        multipleAsignIdStaff: [],
      },
      client_service_id: null,
      permissionCheck: {
        view: "",
        viewAny: true,
        complete: "",
        assing: "",
      },
    };
  },
  methods: {
    findRecordeByProjectId(e){         
      this.$axios
        .get(`staff/clientservice?clientServiceNo=${e.target.value}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
       
          this.filterValue = res.data.data.data[0].id
          this.loadClients()
        
        })
    },
    permissionsCheker() {
      this.permissionCheck.view = this.loadPermissions("clientservice-professional-view");
      // this.permissionCheck.viewAny = this.loadPermissions(
      //   "clientservice-professional-view-any"
      // );

      // this.permissionCheck.assing = this.loadPermissions("clientservice-assign");
      // this.permissionCheck.complete = this.loadPermissions("clientservice-complete");
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    statusChange(status) {
      this.catchStatus = status;
    },
    statusChangeSave() {
      var stsVal;
      var q;
      if (this.catchStatus == "accept") {
        // alert("accept");
        stsVal = "Accepted";
        q = "acceptclientservice";
      } else if (this.catchStatus == "reject") {
        // alert("reject");
        stsVal = "Rejected";
        q = "clientserviceprofessional/reject";
      } else if (this.catchStatus == "complete") {
        // alert("reject");
        stsVal = "complete";
        q = "clientserviceprofessional/complete";
      } else if (this.catchStatus == "decline") {
        // alert("decline");
        stsVal = "Declined";
        q = "declineclientservice";
      }
      this.loading = true;
      this.$axios
        .post(`staff/${q}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res.data;
          //   this.loadClientservices();
          // this.loadClientserviceservices();
         

          toast.success("The Project " + stsVal + " successfully.", {
            autoClose: 1000,
          });

          $("#projectStatusChange").modal("hide");
          this.loadClients()

         
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
                this.$swal.fire({
                  confirmButtonColor: "#00364f",
                  icon: "error",
                  title: "Oops...",
                  text: error.response.data.error,
                });
              });
   
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    valueAssign(client) {
      this.form = {};
      this.form.errors = {};
      this.form.client_service_Professional_id = client.id;
      this.form.client_service_id = client.client_service.id;
      this.catchStatus = 'reject'

      //   this.stsCheck = client.status;
    },

    loadClients(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {


     

                    if( this.filterType == 'all'){
                        this.filterValue = ''
                        this.filterType = ''
                      }


                      if(this.filterValue != null && this.filterType != 'all'){
                        pageUrl += `staff/clientserviceprofessional?status=finalize&per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
                      }else{
                        pageUrl += `staff/clientserviceprofessional?status=finalize&per_page=${this.per_page}`;
                      }




        
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.clientServices = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = ""
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.viewAny = false
          }
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },



//     loadClients(page = null, pg = null) {
//       console.log(page);
//       console.log(pg);
//       this.loading = true;
//       var pageUrl = "";
//       if (this.pagination.last_page_url && pg == "last") {
//         pageUrl = pageUrl + this.pagination.last_page_url;
//       } else if (this.pagination.first_page_url && pg == "first") {
//         pageUrl = pageUrl + this.pagination.first_page_url;
//       } else if (this.pagination.prev_page_url && pg == "prev") {
//         pageUrl = pageUrl + this.pagination.prev_page_url;
//       } else if (this.pagination.next_page_url && pg == "next") {
//         pageUrl = pageUrl + this.pagination.next_page_url;
//       }

//       if (pg == null) {
//         pageUrl += `staff/clientserviceprofessional?status=finalize&per_page=${this.per_page}`;
//       } else {
//         pageUrl += `&per_page=${this.pagination.per_page}`;
//       }
//       if (this.table_search) {
//         pageUrl += `&searchTerm=${this.table_search}`;
//       }
//       if (page != null) {
//         pageUrl += `&page=${page}`;
//       }


//       if (pg == null) {


     

// if( this.filterType == 'all'){
//     this.filterValue = ''
//     this.filterType = ''
//   }


//   if(this.filterValue != null && this.filterType != 'all'){
//     pageUrl += `staff/clientserviceprofessional?status=finalize&per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
//   }else{
//     pageUrl += `staff/clientserviceprofessional?status=finalize&per_page=${this.per_page}`;
//   }





// } else {
// pageUrl += `&per_page=${this.pagination.per_page}`;
// }
// if (this.table_search) {
// pageUrl += `&searchTerm=${this.table_search}`;
// }
// if (page != null) {
// pageUrl += `&page=${page}`;
// }

//       this.$axios
//         .get(pageUrl, {
//           headers: { Authorization: "Bearer " + localStorage.accessToken },
//         })
//         .then((res) => {
//           console.log(res);
//           this.clientServices = res.data.data.data;
//           this.pagination = res.data.data;
//           this.filterValue = ""
//         })
//         .finally(() => (this.loading = false));
//     },
    loadPageTitle(){
        document.title =this.$store.state.staffPageTitles.verification 
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.permissionsCheker();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.table-scroll {
  overflow-y: auto;
}
.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 3px;
}
.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

nav ul {
  height: 300px;
  width: 100%;
}

nav ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-decoration: none;
}

div.scrollmenu a .card {
  height: 50%;
  width: 40%;
}

.come-from-right .modal-dialog {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: auto;
  width: 300px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.come-from-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-right .modal-body {
  padding: 15px 15px 80px;
}
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}



.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}
.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}




.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
